<template>
  <div>
    <!-- select 2 demo -->

    <div>
      <!-- Table Top -->
      <div>
        <b-sidebar
          id="sidebar-right"
          ref="mySidebar"
          bg-variant="white"
          v-model="visibility"
          sidebar-class="sidebar-lg"
          right
          no-header
          backdrop
          shadow
        >
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <h5 class="mb-0">
              {{ sidebarTitle }}
            </h5>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hideSideBar()"
            />
          </div>
          <b-form class="p-2" @submit.prevent>
            <b-row class="mt-1">
              <b-col md="12">
                <b-form-group
                  invalid-feedback="Collection Account is required."
                  ref="type"
                >
                  <div class="d-flex justify-content-between">
                    <label class="bv-no-focus-ring col-form-label pt-0"
                      >Income For</label
                    >
                    <feather-icon
                      class="cursor-pointer"
                      icon="SettingsIcon"
                      color="gray"
                      size="20"
                      v-b-tooltip.hover.top
                      title="Accounts"
                      @click="openAccount()"
                    />
                  </div>
                  <v-select
                    ref="type"
                    :clearable="false"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="accTypes"
                    :reduce="(opt) => opt.id"
                    label="account"
                    v-model="myObj.income.collectionAccountID"
                    placeholder="Select collection account"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Account"
                  invalid-feedback="Account is required."
                  ref="account"
                >
                  <v-select
                    ref="account"
                    :clearable="false"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="accountOptions"
                    :reduce="(opt) => opt.id"
                    label="title"
                    v-model="myObj.income.accountID"
                    placeholder="Select account"
                    @input="CheckAccount()"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Date"
                  invalid-feedback="Date is required."
                  ref="date"
                >
                  <flat-pickr
                    ref="date"
                    :config="config"
                    v-model="myObj.income.date"
                    class="form-control"
                    placeholder="Select Date."
                  />
                  <!-- @on-change="CheckDate()" -->
                </b-form-group>
              </b-col>
              <b-col md="12" v-if="false">
                <b-form-group
                  label="Voucher#"
                  invalid-feedback="Voucher no. is required."
                  ref="voucher"
                >
                  <b-form-input
                    ref="voucher"
                    id="mc-first-name"
                    placeholder="Enter voucher no here"
                    v-model="myObj.income.voucherNumStr"
                    @focusout="CheckVoucher()"
                  />
                </b-form-group>
              </b-col>
              <div
                v-for="(item, ind) in myObj.details"
                :key="ind"
                style="display: flex; align-items: center"
              >
                <b-col md="7" class="pr-0">
                  <b-form-group
                    label="Description"
                    invalid-feedback="Description is required."
                    ref="details"
                  >
                    <b-form-input
                      id="mc-first-name"
                      ref="details"
                      placeholder="Enter description here"
                      v-model="item.details"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3" class="pr-0">
                  <b-form-group
                    label="Amount"
                    invalid-feedback="Amount is required."
                    ref="amount"
                  >
                    <b-form-input
                      type="number"
                      placeholder="amount here"
                      ref="amount"
                      v-model="item.amount"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-button
                    v-if="ind == myObj.details.length - 1"
                    @click="AddDesc()"
                    variant="primary"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                  <b-button
                    v-if="myObj.details.length > 1"
                    @click="removeDesc(item, ind)"
                    variant="outline-danger"
                    class="btn-icon rounded-circle mt-50"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-col>
              </div>
              <!-- <b-col md="12">
                  <span style="font-size: 0.857rem; color: #ea5455;"
                    >Description is required</span
                  >
                </b-col> -->

              <b-col md="12" class="mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="success"
                  @click="Add()"
                  :disabled="request"
                  block
                >
                  <b-spinner v-if="request" small type="grow" />
                  <span v-if="request == false"> Save </span>
                </b-button>
              </b-col>
              <b-col md="12" class="mt-1" v-if="false">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  @click="Add()"
                  :disabled="request"
                  block
                >
                  <b-spinner v-if="request" small type="grow" />
                  <span v-if="request == false"> Save & Print </span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <sidebar-content />
        </b-sidebar>

        <b-sidebar
          id="sidebar-right"
          ref="mySidebar"
          bg-variant="white"
          v-model="visibility2"
          sidebar-class="sidebar-lg"
          right
          no-header
          backdrop
          shadow
        >
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <h5 class="mb-0">Income Report</h5>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="visibility2 = false"
            />
          </div>
          <b-form class="p-2" @submit.prevent>
            <b-row class="">
              <b-col md="12">
                <b-form-group
                  label="Account"
                  invalid-feedback="Account is required."
                  ref="rep_acc"
                >
                  <v-select
                    ref="rep_acc"
                    multiple
                    :closeOnSelect="false"
                    :clearable="false"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="accounts"
                    :reduce="(opt) => opt.id"
                    label="account"
                    v-model="accList"
                    placeholder="Select account"
                    @input="setAccounts()"
                  />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                  label="Date"
                  invalid-feedback="Date is required."
                  ref="date"
                >
                  <flat-pickr
                    ref="date"
                    :config="config2"
                    v-model="rangeDate"
                    class="form-control"
                    placeholder="Select Date."
                    @on-change="setDate()"
                  />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  @click="showReport()"
                  :disabled="request"
                  block
                >
                  <b-spinner v-if="request" small type="grow" />
                  <span v-else> Show </span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          <sidebar-content />
        </b-sidebar>

        <b-sidebar
          id="sidebar-Fee"
          bg-variant="white"
          v-model="accountbar"
          sidebar-class="sidebar-lg"
          right
          no-header
          backdrop
          shadow
        >
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <h5 class="mb-0">Collection Account</h5>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="accountbar = false"
            />
          </div>

          <b-form class="p-2" @submit.prevent>
            <b-row class="d-flex align-items-center">
              <b-col md="10">
                <b-form-group
                  label="Account Name"
                  invalid-feedback="Account name is required."
                >
                  <b-form-input
                    ref="accname"
                    v-model="accountObj.account"
                    placeholder="Enter collection account name"
                    @focusout="accountName()"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-button
                  variant="success"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="saveAcc()"
                  :disabled="savingacc"
                >
                  <b-spinner v-if="savingacc" small type="grow" />
                  <feather-icon v-else :icon="accIcon" />
                </b-button>
              </b-col>
            </b-row>
            <b-table
              class="mt-1"
              :tbody-tr-class="rowClass"
              show-empty
              :items="accTypes"
              :fields="accFields"
              responsive
              hover
            >
              <template #head(actions)="data">
                <div class="text-right">
                  <span>{{ data.label }}</span>
                </div>
              </template>

              <template #cell(actions)="data">
                <div class="text-right">
                  <b-button
                    variant="primary"
                    class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                    @click="editAcc(data.item)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <!-- <b-button
                    variant="outline-danger"
                    class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                    @click="deleteAcc(data.item)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button> -->
                </div>
              </template>
            </b-table>
          </b-form>
          <sidebar-content />
        </b-sidebar>

        <b-card>
          <b-row class="mt-1">
            <b-col
              xl="2"
              lg="3"
              md="4"
              sm="12"
              cols="12"
              class="mb-50"
              v-if="rights.add"
            >
              <b-button
                @click="AddOpen()"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
              >
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle">Income</span>
              </b-button>
            </b-col>
            <b-col xl="2" lg="3" md="4" sm="12" cols="12" class="mb-50">
              <b-button
                @click="visibility2 = true"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
              >
                <span class="align-middle">Report</span>
              </b-button>
            </b-col>

            <b-col
              class="mt-lg-0 mt-md-0 mt-sm-1 mt-1"
              xl="8"
              lg="6"
              md="4"
              sm="12"
              cols="12"
            >
              <b-form-group class="">
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>

                  <b-form-input v-model="searchQuery" placeholder="Search...">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-table
            class="mt-1"
            ref="selectableTable"
            :items="filters"
            show-empty
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :busy="dataLoading"
            responsive
            hover
            :selectable="rights.edit"
            select-mode="single"
            @row-selected="Edit($event[0].us.id)"
          >
            <template #table-busy>
              <div
                class="d-flex justify-content-center mb-3"
                style="margin-top: 50px"
              >
                <b-spinner
                  style="width: 3rem; height: 3rem"
                  type="grow"
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
              </div>
            </template>
            <template #cell(voucherNumStr)="data">
              <b-badge variant="light-primary">
                {{ data.item.us.voucherNumStr }}
              </b-badge>
            </template>
            <template #cell(account)="data">
              {{ data.item.acc.title }}
              <b-badge variant="light-primary"> </b-badge>
            </template>
            <template #cell(date)="data">
              <b-badge variant="light-primary">
                {{
                  new Date(data.item.us.date).toLocaleDateString("en-UK", {
                    year: "numeric",
                    day: "numeric",
                    month: "short",
                  })
                }}
              </b-badge>
            </template>

            <template #cell(total)="data">
              <b-badge variant="light-primary">
                {{ data.item.us.total }}
              </b-badge>
            </template>

            <template #head(actions)="data">
              <div class="text-center">
                <span>{{ data.label }}</span>
              </div>
            </template>

            <template #cell(actions)="data">
              <div class="text-center">
                <b-button
                  v-if="rights.edit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  v-b-tooltip.hover.left
                  title="Edit"
                  :disabled="editLoading && checkId == data.item.us.id"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="Edit(data.item.us.id)"
                >
                  <b-spinner
                    v-if="editLoading && checkId == data.item.us.id"
                    small
                  />
                  <feather-icon v-else icon="EditIcon" class="" />
                </b-button>

                <b-button
                  v-if="rights.delete"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  v-b-tooltip.hover.right
                  title="Delete"
                  class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="Delete(data.item.us.id)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </div>
            </template>
          </b-table>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="filters.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,
    BDropdown,
    BDropdownItem,
    vSelect,
    flatPickr,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
        };
      });
    });
    // console.log(right);
    this.$store.commit("setRights", right);

    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      this.LoadData();
      this.LoadAccounts();
      this.LoadParents();
    }
    // this.LoadData();
    // this.LoadAccounts();
    // this.LoadParents();
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    getColor(ind) {
      console.log("%%%%%% getting color", ind.index);
      return this.color[ind];
    },

    PageChange() {
      console.log(this.currentPage);
      return this.LoadData();
    },
    filters: function () {
      return this.items.filter((pro) => {
        return (
          pro.us.voucherNumStr
            .toLowerCase()
            .match(this.searchQuery.toLowerCase()) ||
          pro.acc.title.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  data() {
    return {
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      rights: {},
      myDepartments: [],
      request: false,
      colorDep: [],
      visibility1: false,

      sidebarTitle: "Add Expense",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        // { label: "voucher", key: "voucherNumStr" },
        { label: "account", key: "account" },
        { label: "date", key: "date" },
        { label: "total", key: "total" },
        { key: "actions", label: "actions" },
      ],
      items: [
        // {
        //   id: 9,
        //   account: 90,
        //   date: "2023-01-10",
        //   details: "new",
        //   campusID: this.$store.state.userData.cId,
        // },
      ],
      accountOptions: [
        // {
        //   id: 1,
        //   code: 999,
        // },
      ],
      selected: null,
      rangeDate: null,
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      typeOptions: [
        { text: "Expense", value: "expense" },
        { text: "Income", value: "income" },
      ],
      myObj: {
        income: {
          id: 0,
          accountID: 0,
          date: "",
          voucherNumStr: "",
          campusID: this.$store.state.userData.cId,
          collectionAccountID: 0,
        },
        details: [
          {
            id: 0,
            details: "",
            amount: 0,
            incomeID: 0,
            campusID: this.$store.state.userData.cId,
          },
        ],
      },
      detailObj: {
        id: 0,
        details: "",
        amount: 0,
        incomeID: 0,
        campusID: this.$store.state.userData.cId,
      },
      depObj: {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      },
      timeout: 10000,
      editLoading: false,
      checkId: 0,

      visibility2: false,
      config2: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        mode: "range",
      },
      accList: [],
      accounts: [{ account: "All", id: 0 }],
      reportObj: {
        dtfrom: "",
        dtto: "",
        accIDs: [],
      },
      accountObj: {
        id: 0,
        campusID: this.$store.state.userData.cId,
        account: "",
        status: "active",
      },
      accountbar: false,
      savingacc: false,
      accFields: [
        { label: "Name", key: "account" },
        { key: "actions", label: "actions" },
      ],
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    openAccount() {
      this.accIcon = "PlusIcon";
      this.accountbar = true;
      this.accountObj = {
        id: 0,
        campusID: this.$store.state.userData.cId,
        account: "",
        status: "active",
      };
      var elem = this.$refs["accname"];
      elem.state = undefined;
    },
    accountName() {
      var elem = this.$refs["accname"];
      if (this.accountObj.account == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveAcc() {
      if (this.accountName() == true) {
        this.savingacc = true;
        if (this.accountObj.id == 0) {
          var status = await this.post({
            url:
              this.$store.state.domain +
              "CollectionAccounts?db=" +
              this.$store.state.userData.db,
            body: this.accountObj,
            message: "Account added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
        } else {
          var status = await this.put({
            url:
              this.$store.state.domain +
              "CollectionAccounts/" +
              this.accountObj.id +
              "?db=" +
              this.$store.state.userData.db,

            message: "Account updated successfully.",
            context: this,
            body: this.accountObj,
            token: this.$store.state.userData.token,
          });
        }
        this.savingacc = false;
        if (status) {
          this.LoadAccounts();
          var elem = this.$refs["accname"];
          elem.state = undefined;
          this.accountObj = {
            id: 0,
            campusID: this.$store.state.userData.cId,
            account: "",
            status: "active",
          };
        }
      }
    },
    async editAcc(acc) {
      this.accountObj = { ...acc };
      this.accIcon = "Edit2Icon";
      var elem = this.$refs["accname"];
      elem.state = undefined;
      // console.log(this.accountObj);
    },

    async deleteAcc(acc) {
      // console.log(acc);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "CollectionAccounts/" +
            acc.id +
            "?db=" +
            this.$store.state.userData.db,

          message: "Account deleted successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadAccounts();
      }
    },

    setAccounts() {
      if (this.accList.at(-1) == 0) {
        this.accList = [0];
      } else {
        this.accList = this.accList.filter((el) => el != 0);
      }
    },
    setDate() {
      let date = this.rangeDate.split(" to ");
      // console.log(date);
      this.reportObj.dtfrom = date[0];
      this.reportObj.dtto = date[1];
    },
    showReport() {
      if (
        this.reportObj.dtfrom == "" ||
        this.reportObj.dtto == "" ||
        this.accList.length == 0
      ) {
        this.$bvToast.toast("Please enter the details", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.request = true;
        if (this.accList[0] !== 0) {
          this.reportObj.accIDs = this.accList;
        } else {
          this.reportObj.accIDs = "";
        }

        var url =
          `https://${this.reportDomain}.myskool.app/Account/Show?type=income&dtFrom=` +
          this.reportObj.dtfrom +
          "&dtTo=" +
          this.reportObj.dtto +
          "&dbb=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&accID=" +
          this.reportObj.accIDs;

        window.open(url, "_blank");
        this.request = false;
      }
    },

    async Edit(id) {
      this.checkId = id;
      this.editLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Income/LoadSelected?db=" +
          this.$store.state.userData.db +
          "&id=" +
          id,
        token: this.$store.state.userData.token,
      };
      this.myObj = await this.get(obj);
      // console.log("editObj:", this.myObj);
      this.editLoading = false;
      this.visibility = true;
      this.sidebarTitle = "Edit Income";

      var elem = this.$refs["account"];
      elem.state = undefined;
      // var elem = this.$refs["voucher"];
      // elem.state = undefined;
      var elem = this.$refs["date"];
      elem.state = undefined;
      var elem = this.$refs["type"];
      elem.state = undefined;
      // var elem = this.$refs["amount"];
      // elem.state = undefined;
      // var elem = this.$refs["details"];
      // elem.state = undefined;
    },

    AddOpen() {
      this.myObj = {
        income: {
          id: 0,
          accountID: 0,
          date: new Date(),
          voucherNumStr: "",
          campusID: this.$store.state.userData.cId,
          collectionAccountID: 0,
        },
        details: [
          {
            id: 0,
            details: "",
            amount: 0,
            incomeID: 0,
            campusID: this.$store.state.userData.cId,
          },
        ],
      };
      this.visibility = true;
      this.sidebarTitle = "Add Income";
      var elem = this.$refs["account"];
      elem.state = undefined;
      // var elem = this.$refs["voucher"];
      // elem.state = undefined;
      var elem = this.$refs["date"];
      elem.state = undefined;
      var elem = this.$refs["type"];
      elem.state = undefined;
      // var elem = this.$refs["amount"];
      // elem.state = undefined;
      // var elem = this.$refs["details"];
      // elem.state = undefined;
    },

    CheckAccount() {
      var elem = this.$refs["account"];
      if (this.myObj.income.accountID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCollection() {
      var elem = this.$refs["type"];
      if (this.myObj.income.collectionAccountID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckVoucher() {
      var elem = this.$refs["voucher"];
      if (this.myObj.income.voucherNumStr == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDetails() {
      // console.log(this.$refs.detail);
      let state = true;
      this.myObj.details.forEach((el) => {
        // console.log(el);
        el.amount = parseInt(el.amount);
        if (isNaN(el.amount) || el.amount == 0 || el.details === "") {
          state = false;
        }
      });
      return state;
      // console.log(elem);
      // if (this.myObj.details == 0) {
      //   return (elem.state = false);
      // } else {
      //   return (elem.state = true);
      // }
    },
    CheckDate() {
      var elem = this.$refs["date"];
      if (this.myObj.income.date == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    hideSideBar() {
      this.visibility = false;
    },

    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Income/loadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let result = await this.get(obj);
      if (result.result == "NotFound") {
        this.items = [];
      } else this.items = result.result;
      this.dataLoading = false;
      // console.log("exp", this.items);
    },
    async LoadParents() {
      var obj2 = {
        url:
          this.$store.state.domain +
          "Accounts/LoadParents?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.accountOptions = await this.get(obj2);
      // console.log("acc", this.accountOptions);
    },
    async LoadAccounts() {
      var obj = {
        url:
          this.$store.state.domain +
          "CollectionAccounts?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let result = await this.get(obj);
      // console.log("acc", result);
      if (result !== "NotFound") {
        this.accTypes = result;
        let temp = [{ account: "All", id: 0 }];
        this.accounts = [...temp, ...result];
      } else {
        this.accTypes = [];
        this.accounts = [];
      }
    },
    AddDesc() {
      this.myObj.details.push({
        id: 0,
        details: "",
        amount: 0,
        incomeID: 0,
        campusID: this.$store.state.userData.cId,
      });
      // console.log(this.myObj);
    },
    async removeDesc(item, ind) {
      if (item.id == 0) {
        this.myObj.details.splice(ind, 1);
        // console.log(this.myObj);
      } else {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "IncomeDetails/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          grading: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.myObj.details.splice(ind, 1);
        // console.log(this.myObj);
      }
    },
    async Add() {
      this.CheckDate();
      this.CheckAccount();
      this.CheckCollection();
      if (
        this.CheckDetails() == false ||
        this.CheckDate() == false ||
        this.CheckAccount() == false ||
        this.CheckCollection() == false
      ) {
        return this.$bvToast.toast(
          "Please fill the income details correctly.",
          {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          }
        );
      } else {
        this.request = true;
        // console.log("obj:", this.myObj);

        var status = await this.post({
          url:
            this.$store.state.domain +
            "Income/Save?db=" +
            this.$store.state.userData.db,
          body: this.myObj,
          message: "Income added successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.LoadData();
          this.visibility = false;
        }
        this.request = false;
      }
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "Income/Remove?db=" +
            this.$store.state.userData.db +
            "&id=" +
            id,
          body: null,
          message: "Income removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
